<template>
  <div>
    <ContentLayout>
      <template #left>
        <div class="flex">
          <div class="panel" v-if="appointData">
            <PanelTitle title="头条预约" :title-class="['fs20']"></PanelTitle>
            <div class="fs14 mt16">预约位置：任务大厅顶部固定位</div>
            <img class="img-example" src="@/assets/images/example-headline.png">
            <div class="date-row">
              <div :class="dateIndex == index ? 'active':''" v-for="(item, index) in appointData.appointments" :key="index"
              @click="dateChange(index)">{{formatDateText(item.appointDate)}}</div>
            </div>
            <div class="appoint-list">
              <div class="appoint-row" v-for="(task, index1) in appointTaskList" :key="index1">
                <AppointTask :detail="task"></AppointTask>
              </div>
              <div class="flex justify-center" v-if="canAppointNum != 0" :style="{'padding-top': !appointTaskList || appointTaskList.length == 0 ? '80px' : '10px'}">
                <div class="btn-appoint btn-fill-theme" @click="toAppoint">头条可预约位仅剩：{{canAppointNum}}个 | 立即预约</div>
              </div>
            </div>
            <div class="rule">{{appointData.appointRule}}</div>
          </div>

          <!-- 我的预约 -->
          <div class="user-appoint">
            <div class="panel">
              <PanelTitle title="我的预约"></PanelTitle>
              <div class="tabs">
                <div :class="[tabIndex == index ? 'active':'']" v-for="(tab, index) in tabList" :key="index" @click="tabClick(index)">{{tab.text}}</div>
              </div>
            </div>
            <div class="list" v-if="userAppointList && userAppointList.length > 0">
              <div v-for="(item, i) in userAppointList" :key="i">
                <div class="panel mt8" v-for="(task, j) in item.appointedTasks" :key="j">
                  <div class="title">
                    <img src="@/assets/images/icon-today.png">
                    <span>{{tabIndex == 0 ? '头条今日展示' : `头条展示日期 ${formatDate(item.appointDate)}`}}</span>
                  </div>
                  <AppointTask :titleLimit="true" :detail="task"></AppointTask>
                  <div class="time-row">
                    <span>预约提交时间：{{task.appointSubmitTime}}</span>
                    <!-- <div class="btn-exchange btn-fill-theme">更换任务</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="panel mt8">
              <Empty tips="暂无预约数据"></Empty>
            </div>
          </div>
        </div>
      </template>
      <template #right>
        <PromoteSection></PromoteSection>
        <Asset class="mt16"></Asset>
      </template>
    </ContentLayout>
    <!-- 预约弹窗 -->
    <AppointPopup ref="appointPopupRef" :rule="appointData?.appointRule || null" @confirm="handleAppoint"></AppointPopup>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import PromoteSection from '@/components/PromoteSection'
import Asset from '@/components/Asset'
import ContentLayout from '@/components/ContentLayout'
import Empty from '@/components/Empty'
import AppointTask from './components/AppointTask.vue'
import AppointPopup from './components/AppointPopup.vue'
import { headlineAppointmentsApi, headlineAppointApi } from '@/api/task.js'
import { dateFormatUtil } from '@/utils/util.js'
export default {
  name: 'HeadlineAppoint',
  components: {
    PanelTitle,
    PromoteSection,
    Asset,
    ContentLayout,
    Empty,
    AppointTask,
    AppointPopup
  },
  data() {
    return {
      tabList: [{text: '显示中', value: 4},{text: '待显示', value: 3},{text: '历史预约', value: 2}],
      tabIndex: 0,
      appointData: null,
      dateIndex: 0,
      appointTaskList: [],
      canAppointNum: 0, 
      userAppointList: []
    }
  },
  created() {
    this.getAppointData()
    this.getUserAppointList()
  },
  methods: {
    getAppointData() {
      headlineAppointmentsApi({type: 1}).then(res => {
        console.log('预约', res)
        this.appointData = res.data
        const current = this.appointData.appointments[this.dateIndex]
        this.appointTaskList = current.appointedTasks
        this.canAppointNum = current.canAppointNum
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getUserAppointList() {
      headlineAppointmentsApi({
        'lastAppointDay': '',
				'type': this.tabList[this.tabIndex].value
      }).then(res => {
        console.log('我的预约', res)
        this.userAppointList = res.data.appointments
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    formatDateText(val) {
      const nowDate = new Date()
      const nMonth = (nowDate.getMonth() + 1).toString().padStart(2, '0')
      const nDay = nowDate.getDate().toString().padStart(2, '0')
      const date = new Date(val)
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      if(nMonth == month && nDay == day) return '今天'
      return month + '-' + day
    },
    dateChange(index) {
      this.dateIndex = index
      const current = this.appointData.appointments[index]
      this.appointTaskList = current.appointedTasks
      this.canAppointNum = current.canAppointNum
    },
    toAppoint() {
      const current = this.appointData.appointments[this.dateIndex]
      this.$refs.appointPopupRef.open({
        appointDay: current.appointDate,
        price: this.appointData.price
      })
    },
    handleAppoint(formData) {
      headlineAppointApi(formData).then(res => {
        this.$refs.appointPopupRef.close()
        this.$tips({message: res.msg, type: 'success'})
        this.getAppointData()
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    tabClick(index) {
      this.tabIndex = index
      this.getUserAppointList()
    },
    formatDate(val) {
      return dateFormatUtil(val)
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 4px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.img-example {
  width: 492px;
  height: 108px;
  margin-top: 4px;
}
.date-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  &>div {
    width: 67px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #FAFAFA;
    font-size: 14px;
    border-radius: 2px 2px 0 0;
    border: 1px solid #F0F0F0;
    cursor: pointer;
  }
  .active {
    background-color: #fff;
    color: var(--theme-color);
    border-bottom: none;
  }
}
.appoint-list {
  border: 1px solid #f5f5f5;
  border-top: none;
  padding: 0 16px;
  min-height: 224px;
  .appoint-row {
    padding: 16px 0;
    border-bottom:1px solid #F5F5F5;
  }
}
.btn-appoint {
  height: 42px;
  line-height: 42px;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
}
.rule {
  margin-top: 16px;
  color: #B0B3BF;
  font-size: 14px;
  white-space: pre-line;
}

// 我的预约
.user-appoint {
  min-width: 340px;
  margin-left: 12px;
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 16px;
    &>div {
      cursor: pointer;
    }
    .active {
      position: relative;
      color: var(--theme-color);
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -14px;
        width: 48px;
        height: 2px;
        background-color: var(--theme-color);
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
  .time-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #B0B3BF;
    margin-top: 16px;
    .btn-exchange {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .list {
    max-height: 640px;
    overflow-y: auto;
  }
}
</style>