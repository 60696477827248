<template>
  <div class="appoint" v-if="detail">
    <div class="flex items-center">
      <img class="avatar" :src="detail.logo || require('@/assets/images/avatar-default.png')">
      <div>
        <div class="flex items-center">
          <span class="pname">{{detail.projectName}}</span>
          <div class="tag">{{detail.taskTypeName}}</div>
        </div>
        <div :class="['title', titleLimit? 'limit':'']">{{detail.taskTitle}}</div>
      </div>
    </div>
    <div>
      <div class="price">{{detail.price}}{{detail.unitPrice}}</div>
      <div class="num">
        <span>完成{{detail.completeNum}}</span>
        <span>剩余 {{detail.leftNum}}</span>
      </div>
    </div>
  </div>
  <!-- <div class="appoint">
    <div class="flex items-center">
      <img class="avatar" src="@/assets/images/avatar-default.png">
      <div>
        <div class="flex items-center">
          <span class="pname">堆堆认证</span>
          <div class="tag">关注</div>
        </div>
        <div :class="['title', titleLimit? 'limit':'']">两秒关注</div>
      </div>
    </div>
    <div>
      <div class="price">20.0元</div>
      <div class="num">
        <span>完成1000</span>
        <span>剩余500</span>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  data(){
    return {

    }
  },
  props: {
    detail: {
      type: Object,
      default: () => null
    },
    titleLimit: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.appoint {
    display: flex;
    justify-content: space-between;
    .avatar {
      width: 36px;
      height: 36px;
      margin-right: 8px;
      border-radius: 18px;
    }
    .pname {
      font-size: 12px;
      color: #65666F;
    }
    .tag {
      font-size: 10px;
      color: #B0B3BF;
      border: 1px solid #B0B3BF;
      padding: 2px 4px;
      border-radius: 15px;
      margin-left: 8px;
    }
    .title {
      font-size: 14px;
      margin-top: 8px;
    }
    .limit {
      width: 130px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .price {
      color: var(--theme-color-red);
      font-size: 16px;
      font-weight: 500;
      text-align: right;
    }
    .num {
      display: flex;
      align-items: center;
      color: #65666F;
      font-size: 11px;
      margin-top: 8px;
      span {
        margin-left: 12px;
      }
    }
  }
</style>