<template>
  <div>
    <Popup title="购买头条预约" ref="popupRef" width="540px" :show-cancel="false" :show-confirm="false" @close="resetForm">
      <div class="flex between-center mb8">
        <div>请选择你的任务：</div>
        <div v-if="currentTask" class="btn-rechoose" @click="$refs.totalPopupRef.open()">
          <span>重新选择</span>
          <img src="@/assets/images/arrow-right-theme.png">
        </div>
      </div>
      <CurrentTask :info="currentTask" @click.native="$refs.totalPopupRef.open()"></CurrentTask>
      <div class="mb8 mt16">支付方式：</div>
      <Payway @change="paywayChange"></Payway>
      <div class="rule">
        <div>购买须知：</div>
        <div style="white-space: pre-line;">{{rule}}</div>
      </div>
      <div class="bottom-row">
        <div class="total">
          <div>合计 <span class="total-num">{{price}}</span>元/天 </div>
          <!-- <div class="tips">点击确认支付，代表你同意 <span class="policy">《自助购买协议》</span> </div> -->
        </div>
        <div class="btn-group">
          <div class="btn-cancel btn-border" @click="close()">取消</div>
          <div class="btn-confirm btn-fill-theme" @click="confirm()">确认支付</div>
        </div>
      </div>
    </Popup>
    <!-- 全部任务弹窗 -->
    <TaskListTotalPopup ref="totalPopupRef" :list="taskList" 
    @confirm="chooseTask" @loadmore="getTaskList($event.page)"></TaskListTotalPopup>
  </div>
</template>

<script>
import Popup from '@/components/Popup'
import TaskListTotalPopup from '@/components/TaskListTotalPopup'
import Payway from '@/components/Payway'
import CurrentTask from "@/components/CurrentTask"
import { headlineTaskChooseListApi } from '@/api/task.js'
import { dateFormatUtil } from '@/utils/util.js'
export default {
  components: {
    Popup,
    Payway,
    TaskListTotalPopup,
    CurrentTask
  },
  data() {
    return {
      taskList: [],
      appointDay: '',
      currentTask: null,
      payMethod: 'BALANCE',
      price: ''
    }
  },
  props: {
    rule: {
      type: String,
      default: ''
    }
  },
  mounted() {
    
  },
  methods: {
    open(info) {
      if(info) {
        this.appointDay = dateFormatUtil(info.appointDay, '')
        this.price = info.price
        console.log('appointDay', this.appointDay)
        this.getTaskList()
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.currentTask = null
    },
    getTaskList(pageNo = 1) {
      headlineTaskChooseListApi({
        'appointDay': this.appointDay,
				'startPage': pageNo - 1 < 0 ? 0 : pageNo - 1,
      }).then(res => {
        const list = res.data.canAppointTasks || []
        if(pageNo == 1) {
          this.taskList = list
        } else {
          this.taskList = this.taskList.concat(list)
          if(list.length == 0) this.$tips({message: '没有更多数据了', type: 'warning'})
        }
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    chooseTask(e) {
      this.currentTask = e.detail
    },
    confirm() {
      if(!this.currentTask) return this.$tips({message: '请选择一个任务', type: 'warning'})
      if(this.payMethod != 'BALANCE') {
        this.$confirm('当前支付方式不可用，请联系客服处理', '温馨提示', {
          confirmButtonText: '去联系'
        }).then(action => {
          if(action == 'confirm') {
            this.$router.push({ name: 'Message', params: {isToCustomer: true}})
          }
        }).catch(err => {})
      } else {
        const formData = {
          'act': 2,
          'appointDay': this.appointDay,
          'payMethod': this.payMethod,
          'price': '100',
          'step': 2,
          'taskId': this.currentTask.taskId,
        }
        this.$emit('confirm', formData)
      }
      
    },
    paywayChange(payMethod) {
      this.payMethod = payMethod
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-rechoose {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}
.rule {
  color: #B0B3BF;
  font-size: 14px;
  margin-top: 24px;
  line-height: 24px;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-cancel, .btn-confirm {
      padding: 10px 24px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: var(--theme-color-red);
    .total-num {
      font-size: 24px;
      font-weight: bold;
    }
    .tips {
      font-size: 12px;
      color: #B0B3BF;
      margin-top: 8px;
    }
    .policy {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}

</style>